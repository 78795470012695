@import "../node_modules/bootstrap/scss/bootstrap";

html,
body {
  padding: 0;
  margin: 0;
  background-image: url(./assets/concrete_wall.png);
  background-repeat: repeat;
  background-color: #fff;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Barlow", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none !important;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.3em;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.83em;
}
h6 {
  font-size: 0.75em;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.6em;
  }
  h2 {
    font-size: 1.1em;
  }
  h3 {
    font-size: 1.1em;
  }
  h4 {
    font-size: 0.95em;
  }
  h5 {
    font-size: 0.8em;
  }
  h6 {
    font-size: 0.7em;
  }
  p {
    font-size: 0.9em;
  }
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.nav-link {
  color: rgb(194, 199, 226) !important;
}

.nav-link:hover {
  color: rgb(149, 156, 196) !important;
}

#profileDropdown::after {
  content: none;
}

hr {
  margin: 0.5em 0 !important;
}

* {
  box-sizing: border-box;
}
