.articleContent section {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgb(231, 231, 231);
}

.articleContent .section2 {
  padding-left: 20px;
  padding-right: 20px;
}

.articleContent .articleSection h1 {
  background-color: #497cb6 !important;
  color: #cedeee;
  padding: 20px;
  margin: 0px;
}

.articleContent .section1 {
  padding-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
