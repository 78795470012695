.navbar {
  background-color: #004f8f;
  font-size: 1em;
  font-family: "Barlow", sans-serif !important;
  font-weight: 600;
}

.notification {
  position: absolute;
  top: 80%;
  left: 100%;
  translate: -50% -50%;
  background-color: #eb1212 !important;
  color: rgb(245, 245, 245) !important;
  border-radius: 50rem !important;
  font-size: 0.6em;
}

.profileDropdown {
  margin-right: 10px;
}

.footer {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #333;
  color: #eee;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.footer a {
  color: rgb(172, 172, 219);
}
.footer a:hover {
  color: rgb(128, 128, 173);
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 100px;
  }
}

.backgroundImage {
  z-index: -1;
}
