.container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 0px;
  padding: 0px;
}

.dashboard {
  background-color: rgb(230, 230, 230);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dashboardTitle {
  padding: 20px;
  background-color: #164e7c;
  color: rgb(230, 230, 230);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.content {
  padding: 20px;
}

.commonActions * .item {
  background-color: rgb(200, 200, 230);
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.consoleNavbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.consoleNavbar {
  display: block;
}

.navBarItem {
}

.navBarItem:not(:last-child) .navBarItem:not(:last-child) {
}

.navBarItem:hover {
  background-color: #00506eb3 !important;
}

.navBarItem {
  background-color: hsla(205, 80%, 31%, 0.75);
  color: rgb(235, 235, 235);
  font-family: "Barlow", sans-serif !important;
  font-weight: 600;
  font-size: 1em;
  min-width: 100%;
  padding-top: 5px;

  padding-bottom: 5px;
  padding-left: 5px;
  margin: 0px;
}

.navBarItem > .navBarItem {
  padding-left: 20px;
  margin-left: -5px;
  display: none;
}

.navBarItem > .navBarItem:nth-child(1) {
  margin-top: 5px;
}

.navBarItem a:hover {
  color: rgb(189, 189, 219);
}

.navBarItem :hover {
  color: rgb(181, 181, 218);
}

.navBarItem > .navBarItem:last-child {
  margin-bottom: -5px;
}

.adminButton {
  background-color: rgb(170, 30, 30) !important;
}

.workspacesButton {
  border-top: 1px solid;
  border-bottom: 1px solid;

  border-color: hsla(198, 100%, 11%);
  background-color: rgb(30, 130, 30) !important;
}

.editor {
  resize: both;
  overflow: auto; /* something other than visible */
}

.button,
.buttonSpecial {
  width: 100%;
  height: calc(100% - 2px);
  margin: 4px;
  color: #fff;
  background-color: hsl(207, 100%, 32%) !important;
  border: hsl(207, 100%, 25%) !important;
}

.buttonSpecial {
  padding-bottom: 20px;
}

.inactiveButton {
  width: 100%;
  margin: 2px;
  background-color: hsl(207, 100%, 12%) !important;
  border: hsl(207, 100%, 15%) !important;
}

.button:hover {
  width: 100%;
  margin: 2px;
  color: hsl(207, 100%, 73%) !important;
}

.hidden {
  display: none;
}

.consoleContent {
}
